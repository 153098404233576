import styled, { css, keyframes } from 'styled-components'
import { device } from '../../../styles/GlobalVariables'

export const GradientContainer = styled.div<{ display: string }>`
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), transparent);
  height: 160px;
  width: 100%;
  top: 0;
  position: absolute;
  padding-top: 5px;
  display: none;

  ${device.small} {
    padding-top: 15px;
    display: ${props => props.display};

    &.full-screen {
      padding-top: 28px;
    }
  }

  .pictureinpicturemode & {
    display: none;
  }
`

export const MarqueeContainer = styled.div`
  pointer-events: none;
  white-space: nowrap;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  width: calc(100% - 70px);
  direction: rtl;
  display: none;
  box-shadow: none;
  font-weight: 400;
  text-align: center;
  color: #fff;
  cursor: default;
  font-size: 18px;
  height: 35px;
  z-index: 100;
  position: absolute;
  top: 10px;

  &.full-screen-true {
    top: 15px;
  }

  ${device.medium} {
    display: ${props => props.theme.display};
    height: 160px;
  }
`

export const marqueeAnimation = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(200%);
  }
`

export const Text = styled.div<{
  shouldScroll: boolean
  speed: number
  color: string
  fontSize: number
}>`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  color: ${props => props.color};
  font-size: ${props => props.fontSize + 'px'};
  font-weight: 400;
  gap: 36px;
  overflow: visible;
  flex-wrap: nowrap;
  justify-content: flex-end;
  ${({ shouldScroll, speed }) =>
    shouldScroll
      ? css`
          animation: ${marqueeAnimation} ${speed}s linear infinite;
        `
      : css`
          animation: none;
        `}
`
export const TextItem = styled.div`
  display: inline-flex;
  min-width: fit-content;
  position: relative;
  bottom: 65px;
`
